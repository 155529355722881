import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import LoadingCover from '../../Shared/LoadingCover/LoadingCover'
import React, { useEffect, useState } from 'react'
import { setUser } from '../../../store/userSlice'
import { getCookie } from '../../../hooks/cookies'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import './Home.scss'

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)

  const getUserInfo = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_vendor_info/${getCookie(
          'userId'
        )}`,
        { headers: { Authorization: `Bearer ${getCookie('userToken')}` } }
      )
      dispatch(setUser(data))
      // if (
      //   data.has_company &&
      //   location.pathname.split('/')[1] !== '/company-info'
      // ) {
      //   navigate('/')
      // }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const sendHash = async () => {
    const hash = searchParams.get('hash')
    if (!hash) {
      // navigate('/company-info')
      return
    }
    const formData = new FormData()
    formData.append('hash', hash)
    formData.append('hash_type', 'registration')
    try {
      const { data } = await axios.post(
        'https://api.travelingo.pro/api/vendor/check_hash',
        formData,
        {
          headers: {
            Authorization: `Bearer api_for_travelingo`,
          },
        }
      )
      if (data.status === 'error') {
        alert(data.error)
      } else {
        dispatch(
          setUser({ ...user, user_id: data.user_id, token_api: data.token_api })
        )
        localStorage.setItem('userId', data.user_id)
        localStorage.setItem('userToken', data.token_api)
        navigate('/company-info')
      }
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    if (!user.has_company && user.has_company !== undefined) {
      navigate('/company-info')
    }
  }, [user.has_company])

  useEffect(() => {
    sendHash()
    if (!!getCookie('userToken')) {
      getUserInfo()
    }
  }, [])

  return (
    <div className='home'>
      <Outlet />
      {isLoading ? <LoadingCover /> : ''}
    </div>
  )
}

export default React.memo(Home)
