import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from './images/logo.svg'
import { ReactComponent as LockIcon } from './images/lock.svg'
import Input from '../../Shared/Input/Input'
import { Link, useNavigate } from 'react-router-dom'
import { emailRegex } from '../../../hooks/regex'

import './ForgotPassword.scss'
import { pageTitles } from '../../../static/constants/pageTitles'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleEmailInput = (event) => {
    setEmail(event.target.value)
  }

  const handleSubmit = () => {
    navigate('/forgot-password/confirmation-sent')
  }

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.FORGOT_PASSWORD}`
  }, [])

  return (
    <div className='forgot-password'>
      <Logo />
      <LockIcon className='forgot-password__lock-icon' />
      <h3 className='forgot-password__title'>Forgot Password?</h3>
      <span className='forgot-password__description'>
        No worries, we'll send you reset instructions
      </span>
      <form className='forgot-password__form' onSubmit={handleSubmit}>
        <Input
          isRequired
          label='Enter your email'
          value={email}
          type='email'
          pattern={emailRegex.source}
          onChange={handleEmailInput}
          errorMessage='Please provide valid email address'
        />
        <button className='forgot-password__submit'>Reset Password</button>
        <Link to='/sign-in' className='forgot-password__link'>
          Back to Login
        </Link>
        <span className='forgot-password__sign-up'>
          Don't have an account?{' '}
          <Link to='/sign-up' className='forgot-password__sign-up-link'>
            Sign Up
          </Link>
        </span>
      </form>
    </div>
  )
}

export default ForgotPassword
