import React from 'react'
import { ReactComponent as Logo } from './images/logo.svg'
import { ReactComponent as EmailIcon } from './images/email.svg'
import { Link } from 'react-router-dom'

import './SignUpSent.scss'

const SignUpSent = () => {
  return (
    <div className='sign-up-sent'>
      <Logo />
      <EmailIcon className='sign-up-sent__lock-icon' />
      <h3 className='sign-up-sent__title'>Check your mail</h3>
      <span className='sign-up-sent__description'>
        We have sent a link to confirm your registration
      </span>
      <Link to='/sign-up' className='sign-up-sent__back-link'>
        Back
      </Link>
      <span className='sign-up-sent__change-email'>
        Did not receive the email? Check your spam filter,{' '}
        <Link to='/sign-up' className='sign-up-sent__go-back'>
          or try again
        </Link>
      </span>
    </div>
  )
}

export default SignUpSent
