import React from 'react'
import MinimalInput from '../../../../Shared/MinimalInput/MinimalInput'
import { ReactComponent as FacebookImage } from './images/facebook.svg'
import { ReactComponent as WhatsAppImage } from './images/whatsapp.svg'
import { ReactComponent as TelegramImage } from './images/telegram.svg'

import './SocialInputs.scss'

const SocialInputs = ({
  whatsapp,
  telegram,
  facebook,
  setWhatsapp,
  setTelegram,
  setFacebook,
}) => {
  return (
    <div className='social-inputs'>
      <div className='social-inputs__social-profile'>
        <FacebookImage className='social-inputs__image' />
        <MinimalInput
          value={facebook}
          onChange={setFacebook}
          placeholder='Page link'
        />
      </div>
      <div className='social-inputs__social-profile'>
        <WhatsAppImage className='social-inputs__image' />
        <MinimalInput
          value={whatsapp}
          onChange={setWhatsapp}
          placeholder='Phone number'
        />
      </div>
      <div className='social-inputs__social-profile'>
        <TelegramImage className='social-inputs__image' />
        <MinimalInput
          value={telegram}
          onChange={setTelegram}
          placeholder='Telegram id @'
        />
      </div>
    </div>
  )
}

export default SocialInputs
