import React from 'react'
import Modal from 'react-modal'
import CustomButton, {
  BUTTON_STYLES,
} from '../../../../Shared/CustomButton/CustomButton'
import ColorPicker from '../../../../Shared/ColorPicker/ColorPicker'
import Input from '../../../../Shared/Input/Input'
import ErrorMessage from '../../../../Shared/ErrorMessage/ErrorMessage'

import './CreatePointForm.scss'

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
    zIndex: 999,
  },
  content: {
    padding: '0 105px 42px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '16px',
  },
}

const CreatePointForm = ({
  isOpen,
  onClose,
  onSubmit,
  address,
  cashback,
  minTransactionAmount,
  maxTransactionAmount,
  phone,
  color,
  changeAddress,
  changeCashback,
  changeMinAmount,
  changeMaxAmount,
  changePhone,
  changeColor,
  errorMessage,
}) => {
  return (
    <Modal
      className='create-point-form'
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <form onSubmit={onSubmit}>
        <h3 className='create-point-form__title'>Create new point</h3>
        <p className='create-point-form__description'>
          Please provide info for a new point in your company
        </p>
        <div className='create-point-form__input-wrapper'>
          <Input
            isRequired
            label='Address'
            value={address}
            onChange={changeAddress}
            type='input'
          />
        </div>
        <div className='create-point-form__input-wrapper'>
          <Input
            isRequired
            label='Cashback'
            value={cashback}
            onChange={changeCashback}
            type='number'
          />
        </div>
        <div className='create-point-form__input-wrapper'>
          <Input
            label='Max transaction Amount'
            value={maxTransactionAmount}
            onChange={changeMaxAmount}
            type='number'
          />
        </div>
        <div className='create-point-form__input-wrapper'>
          <Input
            label='Min transaction Amount'
            value={minTransactionAmount}
            onChange={changeMinAmount}
            type='number'
          />
        </div>
        <div className='create-point-form__input-wrapper'>
          <Input
            isRequired
            label='Phone'
            value={phone}
            onChange={changePhone}
            type='phone'
          />
        </div>
        <div className='create-point-form__input-wrapper'>
          <ColorPicker
            isRequired
            label='Color'
            value={color}
            onChange={changeColor}
            type='color'
          />
        </div>
        <ErrorMessage message={errorMessage} />
        <div className='create-point-form__buttons'>
          <CustomButton label='Create Point' type='submit' />
          <div className='create-point-form__button-cancel'>
            <CustomButton
              style={BUTTON_STYLES.EMPTY}
              label='Cancel'
              type='button'
              onClick={onClose}
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CreatePointForm
