import CustomButton from '../../../Shared/CustomButton/CustomButton'
import { ReactComponent as GiftIcon } from './images/gift-icon.svg'
import { ReactComponent as AppImage } from './images/app.svg'
import CompanyForm from './CompanyForm/CompanyForm'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import React, { useEffect, useState } from 'react'

import './CompanyInfo.scss'
import axios from 'axios'
import { getCookie } from '../../../../hooks/cookies'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../../store/userSlice'
import { pageTitles } from '../../../../static/constants/pageTitles'

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
  },
  content: {
    padding: '48px 105px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '16px',
  },
}

const CompanyInfo = () => {
  const [companyName, setCompanyName] = useState('')
  const [tagline, setTagline] = useState('')
  const [category, setCategory] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangeCompanyName = (event) => {
    setCompanyName(event.target.value)
  }

  const handleChangeTagline = (event) => {
    setTagline(event.target.value)
  }

  const handleChangeCategory = (category) => {
    setCategory(category)
  }

  const handleChangeAddress = (event) => {
    setAddress(event.target.value)
  }

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value)
  }

  const handleSubmitCompanyInfo = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('company_name', companyName)
      formData.append('tagline', tagline)
      formData.append('category_id', category.id)
      formData.append('main_address', address)
      formData.append('phone', phoneNumber)
      const { data } = await axios.post(
        'https://api.travelingo.pro/api/vendor/create_company',
        formData,
        { headers: { Authorization: `Bearer ${getCookie('userToken')}` } }
      )
      dispatch(setUser({ ...user, has_company: true }))
      navigate('/')
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.COMPANY_INFO}`
  }, [])

  return (
    <div className='company-info'>
      <div className='company-info__left'>
        <h2 className='company-info__title'>Company info</h2>
        <span className='company-info__description'>
          The completed data can be changed later
        </span>
        <div className='company-info__form'>
          <CompanyForm
            companyName={companyName}
            tagline={tagline}
            category={category}
            address={address}
            phoneNumber={phoneNumber}
            isLoading={isLoading}
            setCompanyName={handleChangeCompanyName}
            setTagline={handleChangeTagline}
            setCategory={handleChangeCategory}
            setAddress={handleChangeAddress}
            setPhoneNumber={handleChangePhoneNumber}
            onSubmit={handleSubmitCompanyInfo}
          />
        </div>
      </div>
      <div className='company-info__right'>
        <AppImage />
      </div>
      <Modal style={customStyles}>
        <GiftIcon />
        <h3 className='modal__title'>Congratulations!</h3>
        <p className='modal__description'>
          Your company{' '}
          <span className='modal__description-name'>
            London Hilton on Park Lane
          </span>{' '}
          has been successfully registered
        </p>
        <p className='modal__hint'>
          Users can find you in the app after paying for the{' '}
          <Link className='modal__hint-link'>tariff plan</Link>
        </p>
        <div className='modal__submit-wrapper'>
          <CustomButton label='Start Using' />
        </div>
      </Modal>
    </div>
  )
}

export default CompanyInfo
