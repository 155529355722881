import React, { useEffect, useState } from 'react'
import ProfileSubheader from '../../../Shared/ProfileSubheader/ProfileSubheader'
import LoadingCover from '../../../Shared/LoadingCover/LoadingCover'
import CreatePointForm from './CreatePointForm/CreatePointForm'
import FormButton from '../../../Shared/FormButton/FormButton'
import AddressBlock from './AddressBlock/AddressBlock'
import { getCookie } from '../../../../hooks/cookies'
import { useSelector } from 'react-redux'
import axios from 'axios'

import './Branches.scss'
import EmptyPlaceholder from '../../../Shared/EmptyPlaceholder/EmptyPlaceholder'
import ErrorMessage from '../../../Shared/ErrorMessage/ErrorMessage'
import { pageTitles } from '../../../../static/constants/pageTitles'

const Branches = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [points, setPoints] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const company = useSelector((state) => state.company.company)

  const [pointAddress, setPointAddress] = useState('')
  const [cashback, setCashback] = useState(0)
  const [maxTransactionAmount, setMaxTransactionAmount] = useState('')
  const [minTransactionAmount, setMinTransactionAmount] = useState('')
  const [createErrorMessage, setCreateErrorMessage] = useState('')
  const [listErrorMessage, setListErrorMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [color, setColor] = useState('')

  const handleChangeAddress = (event) => {
    setPointAddress(event.target.value)
  }

  const handleChangeCashback = (event) => {
    setCashback(event.target.value)
  }

  const handleChangeMaxTransactionAmount = (event) => {
    setMaxTransactionAmount(event.target.value)
  }

  const handleChangeMinTransactionAmount = (event) => {
    setMinTransactionAmount(event.target.value)
  }

  const handleChangePhone = (event) => {
    setPhone(event.target.value)
  }

  const handleChangeColor = (event) => {
    setColor(event.target.value)
  }

  const handleOpenCreateModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseCreateModal = () => {
    setIsModalOpen(false)
    setCreateErrorMessage('')
    setPointAddress('')
    setCashback('')
    setMinTransactionAmount('')
    setMaxTransactionAmount('')
    setPhone('')
    setColor('#000')
  }

  const getAllPoints = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_point_info`,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      setPoints(data)
    } catch (error) {
      setListErrorMessage(error.message)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const createNewPoint = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('address', pointAddress)
      formData.append('cashback_percent', cashback)
      formData.append(
        'max_amount',
        maxTransactionAmount ? maxTransactionAmount : 0
      )
      formData.append(
        'min_amount',
        minTransactionAmount ? minTransactionAmount : 0
      )
      formData.append('phone', phone ? phone : 0)
      formData.append('color_tag', color)
      const { data } = await axios.post(
        `https://api.travelingo.pro/api/vendor/create_point`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      setPoints([...points, data])
      setIsModalOpen(false)
    } catch (error) {
      setCreateErrorMessage(error.message)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeletePoint = async (id) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      await axios.post(
        `https://api.travelingo.pro/api/vendor/delete_point/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
      setPoints(points.filter((point) => point.id !== Number(id)))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (company.id) {
      getAllPoints()
    }
  }, [company.id])

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.BRANCHES}`
  }, [])

  return (
    <div className='branches'>
      <ProfileSubheader
        title='Branches'
        description='Add the addresses of your points'
      >
        <div className='about-company__buttons'>
          <FormButton
            label='+ Add New Address'
            onSubmit={handleOpenCreateModal}
          />
        </div>
      </ProfileSubheader>
      <div className='branches__addresses'>
        {points.map((point, index) => {
          return (
            <AddressBlock
              key={point.id}
              id={point.id}
              adressNumber={index + 1}
              address={point?.address}
              cashbackPercent={point.cashback_percent}
              maxTransactionAmount={point.max_amount}
              minTransactionAmount={point.min_amount}
              phone={point.phone}
              color={point.color_tag}
              walletAddress={point.wallet_info?.address}
              deletePoint={handleDeletePoint}
            />
          )
        })}
      </div>
      {isLoading ? <LoadingCover /> : ''}
      <CreatePointForm
        isOpen={isModalOpen}
        onClose={handleCloseCreateModal}
        companyId={company.id}
        onSubmit={createNewPoint}
        address={pointAddress}
        cashback={cashback}
        minTransactionAmount={minTransactionAmount}
        maxTransactionAmount={maxTransactionAmount}
        phone={phone}
        color={color}
        changeAddress={handleChangeAddress}
        changeCashback={handleChangeCashback}
        changeMinAmount={handleChangeMinTransactionAmount}
        changeMaxAmount={handleChangeMaxTransactionAmount}
        changePhone={handleChangePhone}
        changeColor={handleChangeColor}
        errorMessage={createErrorMessage}
      />
      {!points.length && !isLoading ? (
        <div className='branches__empty-placeholder'>
          <EmptyPlaceholder message='You have no active points. Create one above' />
        </div>
      ) : listErrorMessage ? (
        <ErrorMessage message={listErrorMessage} />
      ) : (
        ''
      )}
    </div>
  )
}

export default React.memo(Branches)
