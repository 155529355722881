import React from 'react'
import { ReactComponent as EyeIcon } from './images/eye.svg'
import { ReactComponent as ErrorIcon } from './images/exclamation.svg'

import './PasswordInput.scss'

const PasswordInput = ({
  value,
  onChange,
  isRequired,
  label,
  placeholder,
  togglePassword,
  isPasswordShown,
  errorMessage,
  pattern,
}) => {
  return (
    <label className='password-input'>
      <span className='password-input__label'>
        {label}
        <span className='password-input__required-star'>
          {isRequired ? '*' : ''}
        </span>
      </span>
      <div className='password-input__input-wrapper'>
        <input
          required={isRequired}
          className='password-input__input'
          type={isPasswordShown ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          pattern={pattern}
        />
        <div className='input__error-message'>
          <ErrorIcon className='input__error-icon' />
          <span className='input__error-text'>{errorMessage}</span>
        </div>
        <button
          onClick={togglePassword}
          className='password-input__show-password'
          type='button'
        >
          <EyeIcon
            className={`password-input__password-icon ${
              isPasswordShown ? 'password-input__password-icon--active' : ''
            }`}
          />
        </button>
      </div>
    </label>
  )
}

export default PasswordInput
