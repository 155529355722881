import React, { useState } from 'react'
import Toggle from '../../../../../Shared/Toggle/Toggle'
import { ReactComponent as EditCashierIcon } from './images/edit-cashier.svg'
import { getCookie } from '../../../../../../hooks/cookies'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import './Cashier.scss'

const Cashier = ({
  id,
  name,
  lastName,
  email,
  address,
  isCashierActive,
  addressColor,
}) => {
  const [isActive, setIsActive] = useState(isCashierActive)
  const navigate = useNavigate()

  const handleToggleActive = async () => {
    const initialActive = isActive
    try {
      setIsActive(!initialActive)
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('cashier_id', id)
      formData.append('status', Number(!initialActive))
      await axios.post(
        `https://api.travelingo.pro/api/vendor/set_cashier_status`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
    } catch (error) {
      console.log(error)
      setIsActive(initialActive)
    }
  }

  const toEdit = () => {
    navigate(`${id}`)
  }

  return (
    <tr className='cashier-list__table-row'>
      <td className='cashier-list__table-cell'>
        {name} {lastName}
      </td>
      <td className='cashier-list__table-cell'>{email}</td>
      <td className='cashier-list__table-cell cashier-list__table-cell-address'>
        <div
          className='cashier-list__address-color'
          style={{ backgroundColor: addressColor }}
        />
        {address}
      </td>
      <td className='cashier-list__table-cell cashier-list__toggle'>
        <Toggle isActive={isActive} onToggle={handleToggleActive} />
      </td>
      <td className='cashier-list__table-cell-5'>
        <button className='cashier-list__edit-cashier' onClick={toEdit}>
          <EditCashierIcon />
        </button>
      </td>
    </tr>
  )
}

export default Cashier
