import React from 'react'

import './FormButton.scss'

const FormButton = ({ label, onSubmit }) => {
  return (
    <button className='form-button' onClick={onSubmit}>
      {label}
    </button>
  )
}

export default FormButton
