import FilterDropdown from '../../../../Shared/FilterDropdown/FilterDropdown'
import { ReactComponent as SearchIcon } from './images/search-icon.svg'
import { setSearchQuery } from '../../../../../store/cashiersSlice'
import { ReactComponent as FilterIcon } from './images/filter.svg'
import { useDispatch, useSelector } from 'react-redux'
import StaffFilter from '../StaffFilter/StaffFilter'
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getCookie } from '../../../../../hooks/cookies'

import './ControlBar.scss'

const ControlBar = () => {
  const [points, setPoints] = useState([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const searchQuery = useSelector((state) => state.cashiers.searchQuery)
  const company = useSelector((state) => state.company.company)
  const location = useLocation()

  const isDisabled = location.pathname.includes('/staff/')

  const dispatch = useDispatch()

  const handleToggleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const handleCloseFilter = () => {
    setIsFilterOpen(false)
  }

  const handleChangeSearchInput = (event) => {
    dispatch(setSearchQuery(event.target.value))
  }

  const getAllPoints = async () => {
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_point_info`,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      setPoints(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllPoints()
  }, [company.id])

  return (
    <div className='control-bar'>
      <div className='control-bar__filter-wrapper'>
        <button
          className='control-bar__filter-button'
          onClick={handleToggleFilter}
          disabled={isDisabled}
        >
          <FilterIcon />
          <span className='control-bar__filter-button-text'>Filter</span>
        </button>
        {isFilterOpen ? (
          <FilterDropdown>
            <StaffFilter points={points} onClose={handleCloseFilter} />
          </FilterDropdown>
        ) : (
          ''
        )}
      </div>
      <div className='control-bar__input-wrapper'>
        <input
          className='control-bar__input'
          type='text'
          placeholder='Search'
          value={searchQuery}
          onChange={handleChangeSearchInput}
          disabled={isDisabled}
        />
        <SearchIcon className='control-bar__input-icon' />
      </div>
      <Link to='new-cashier' className='control-bar__submit-button'>
        + Add an Employee
      </Link>
    </div>
  )
}

export default ControlBar
