import React from 'react'
import EmptyPlaceholder from '../../../../Shared/EmptyPlaceholder/EmptyPlaceholder'
import { setBranchFilters } from '../../../../../store/cashiersSlice'
import { ReactComponent as FilterIcon } from './images/filter.svg'
import { ReactComponent as CloseIcon } from './images/close.svg'
import { useDispatch, useSelector } from 'react-redux'

import './StaffFilter.scss'

const StaffFilter = ({ points, onClose }) => {
  const dispatch = useDispatch()
  const branchFilters = useSelector((state) => state.cashiers.branchFilters)

  const checkPoint = (checkedPoint) => {
    if (branchFilters.includes(checkedPoint.id)) {
      dispatch(
        setBranchFilters(branchFilters.filter((id) => id !== checkedPoint.id))
      )
    } else {
      dispatch(setBranchFilters([...branchFilters, checkedPoint.id]))
    }
  }

  return (
    <div className='staff-filter'>
      <div className='staff-filter__header'>
        <div className='staff-filter__header-left'>
          <FilterIcon />
          <span className='staff-filter__title'>Filter</span>
        </div>
        <button className='staff-filter__header-close-button' onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <ul className='staff-filter__branch-list'>
        {points.map((point) => {
          return (
            <li key={point.id} className='staff-filter__branch-item'>
              <input
                type='checkbox'
                checked={branchFilters.includes(point.id)}
                onChange={() => checkPoint(point)}
              />
              <div
                className='staff-filter__branch-item-color-tag'
                style={{ backgroundColor: point.color_tag }}
              />
              <span className='staff-filter__branch-item-name'>
                {point.address}
              </span>
            </li>
          )
        })}
        {!points.length && <EmptyPlaceholder message='No branches found' />}
      </ul>
    </div>
  )
}

export default StaffFilter
