import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from './images/edit.svg'
import Cashier from './Cashier/Cashier'
import { useSelector } from 'react-redux'
import EmptyPlaceholder from '../../../../Shared/EmptyPlaceholder/EmptyPlaceholder'

import './CashierList.scss'

const CashierList = () => {
  const branchFilters = useSelector((state) => state.cashiers.branchFilters)
  const cashiers = useSelector((state) => state.cashiers.cashiers)
  const searchQuery = useSelector((state) => state.cashiers.searchQuery)
  const [filteredCashiers, setFilteredCashiers] = useState(cashiers)

  useEffect(() => {
    setFilteredCashiers(
      cashiers.filter((cashier) =>
        (cashier.name + ' ' + cashier.surname)
          .toLowerCase()
          .includes(searchQuery.toLowerCase().trim())
      )
    )
  }, [searchQuery, cashiers])

  useEffect(() => {
    if (branchFilters.length) {
      setFilteredCashiers(
        cashiers.filter((cashier) =>
          branchFilters.includes(cashier.point_info.id)
        )
      )
    } else {
      setFilteredCashiers(cashiers)
    }
  }, [branchFilters, cashiers])

  return (
    <div className='cashier-list'>
      <span className='cashier-list__cashiers-amount'>
        Transactions found: {cashiers.length}
      </span>
      <table className='cashier-list__table'>
        <tr className='cashier-list__table-row-title'>
          <td className='cashier-list__table-cell-title'>Employee</td>
          <td className='cashier-list__table-cell-title'>Email</td>
          <td className='cashier-list__table-cell-title'>Branch</td>
          <td className='cashier-list__table-cell-title cashier-list__toggle'>
            Status
          </td>
          <td className='cashier-list__table-cell-title-5'>
            <EditIcon />
          </td>
        </tr>
        {filteredCashiers.map((cashier) => {
          return (
            <Cashier
              key={cashier.id}
              id={cashier.user_id}
              name={cashier.name}
              lastName={cashier.surname}
              email={cashier.email}
              addressColor={cashier.point_info.color_tag}
              address={cashier.point_info.address}
              isCashierActive={cashier.active_user}
            />
          )
        })}
      </table>
      {!cashiers.length ? (
        <div className='cashier-list__empty-list'>
          <EmptyPlaceholder message='No cashiers found. Create one above' />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CashierList
