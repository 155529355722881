import React from 'react'
import Badge from '../../../Shared/Badge/Badge'
import Input from '../../../Shared/Input/Input'
import Checkbox from '../../../Shared/Checkbox/Checkbox'
import CustomButton from '../../../Shared/CustomButton/CustomButton'
import { Link } from 'react-router-dom'
import { emailRegex, passwordRegex } from '../../../../hooks/regex'
import ErrorMessage from '../../../Shared/ErrorMessage/ErrorMessage'
import CountrySelect from '../../../Shared/CountrySelect/CountrySelect'

import './SignUpForm.scss'

const SignUpForm = ({
  isLoading,
  firstName,
  lastName,
  email,
  password,
  passwordRepeat,
  country,
  errorMessage,
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setPasswordRepeat,
  setCountry,
  submitForm,
}) => {
  return (
    <div className='sign-up-form'>
      <h3 className='sign-up-form__title'>Get Started Now</h3>
      <div className='sign-up-form__badge-wrapper'>
        <Badge label='7 days free' />
      </div>
      <form className='sign-up-form__form' onSubmit={submitForm}>
        <div className='sign-up-form__form-inputs'>
          <Input
            value={firstName}
            onChange={setFirstName}
            label='First name'
            isRequired
          />
          <Input value={lastName} onChange={setLastName} label='Last name' />
          <Input
            value={email}
            onChange={setEmail}
            label='Email'
            pattern={emailRegex.source}
            isRequired
            errorMessage='Please provide valid email address'
          />
          <div className='sign-up-form__form-country-wrapper'>
            <span className='sign-up-form__form-country-label'>
              Country
              <span className='sign-up-form__form-country-label-star'>*</span>
            </span>
            <CountrySelect
              selectedOption={country}
              onChange={setCountry}
              className='sign-up-form__country-select'
            />
          </div>
          <div className='sign-up-form__password-wrapper'>
            <Input
              value={password}
              onChange={setPassword}
              label='Password'
              type='password'
              isRequired
              pattern={passwordRegex.source}
            />
            <span className='sign-up-form__password-label'>
              min 8 chars (A-Z, 0-9)
            </span>
          </div>
          <Input
            value={passwordRepeat}
            onChange={setPasswordRepeat}
            label='Re-enter password'
            type='password'
            isRequired
            pattern={passwordRegex.source}
          />
        </div>
        <div className='sign-up-form__checkbox-wrapper'>
          <Checkbox
            label={`I've read and agree with Terms of Service and our Privacy Policy`}
            isRequired
          />
        </div>

        {errorMessage ? (
          <div className='sign-up__error-message'>
            <ErrorMessage message={errorMessage} />
          </div>
        ) : (
          ''
        )}
        <div className='sign-up-form__submit-wrapper'>
          <CustomButton label='Create Account' isLoading={isLoading} />
        </div>
        <span className='sign-up__sign-in'>
          Already have an account?{' '}
          <Link to='/sign-in' className='sign-up__sign-in-link'>
            Sign In
          </Link>
        </span>
      </form>
    </div>
  )
}

export default SignUpForm
