import React, { useState } from 'react'
import { ReactComponent as TrvIcon } from './images/trv.svg'
import { ReactComponent as Arrow } from './images/arrow.svg'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Link } from 'react-router-dom'

import './AddressPricing.scss'

const AddressPricing = ({ walletInfo }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleToggleOpen = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleDropdownClose = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }

  const ref = useDetectClickOutside({ onTriggered: handleDropdownClose })

  return (
    <div
      className={`address-pricing ${
        isDropdownOpen ? 'address-pricing--isOpen' : ''
      }`}
      onClick={handleToggleOpen}
      ref={ref}
    >
      <span className='address-pricing__amount'>
        {walletInfo.balance?.toFixed(0) || '~'} <TrvIcon />
      </span>
      <Arrow className={isDropdownOpen ? 'address-pricing--rotated' : ''} />
      {isDropdownOpen ? (
        <ul className='address-pricing__dropdown'>
          <li className='address-pricing__dropdown-item'>
            <Link to='' className='address-pricing__link'>
              Top Up
            </Link>
          </li>
          <li className='address-pricing__dropdown-item'>
            <Link to='' className='address-pricing__link'>
              Transfer
            </Link>
          </li>
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddressPricing
