import React from 'react'

import './LoadingBranchCover.scss'

const LoadingBranchCover = () => {
  return (
    <div className='loading-branch-cover'>
      <div className='loading-branch-cover__loader' />
    </div>
  )
}

export default LoadingBranchCover
