import React from 'react'

import './MinimalTextarea.scss'

const MinimalTextarea = ({ defaultValue, value, disabled, onChange }) => {
  return (
    <textarea
      className='minimal-textarea'
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default MinimalTextarea
