import React from 'react'

import './TariffBlock.scss'

const TariffBlock = () => {
  return (
    <div className='tariff-block'>
      <div className='tariff-block__info'>
        <div className='tariff-block__type'>Lite</div>
        <span className='tariff-block__price'>15 €/month</span>
      </div>
      <span className='tariff-block__dateline'>
        Paid until{' '}
        <span className='tariff-block__date'>September 02, 2024</span>
      </span>
    </div>
  )
}

export default TariffBlock
