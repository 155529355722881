import axios from 'axios'
import React, { useEffect, useState } from 'react'

import './CountrySelect.scss'

const CountrySelect = ({ selectedOption, onChange }) => {
  const [countries, setCountries] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const getCountries = async () => {
    try {
      const { data } = await axios.get(
        'https://api.travelingo.pro/api/vendor/get_countries',
        {
          headers: {
            Authorization: 'Bearer api_for_travelingo',
          },
        }
      )
      setCountries(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <div onClick={handleToggleDropdown} className='country-select'>
      {selectedOption.name || (
        <span className='country-select__placeholder'>-</span>
      )}
      {isOpen ? (
        <ul className='country-select__list'>
          {countries.map((country) => {
            return (
              <li className='country-select__list-item' key={country.id}>
                <button
                  onClick={() => {
                    onChange(country)
                  }}
                  className='country-select__button'
                  type='button'
                >
                  <span className='country-select__code'>
                    {country.country_code}
                  </span>{' '}
                  {country.name}
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default CountrySelect
