import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import companyReducer from './companySlice'
import cashiersReducer from './cashiersSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    cashiers: cashiersReducer,
  },
})
