import React from 'react'
import ControlBar from './ControlBar/ControlBar'
import Header from '../../Header/Header'
import { Outlet } from 'react-router'

import './Staff.scss'

const Staff = () => {
  return (
    <div className='staff'>
      <Header title='Staff' isBalanceHidden />
      <div className='staff__bar-wrapper'>
        <ControlBar />
      </div>
      <Outlet />
    </div>
  )
}

export default Staff
