import React from 'react'
import { NavLink } from 'react-router-dom'

import './ProfileNavigation.scss'

const ProfileNavigation = () => {
  return (
    <nav className='profile-navigation'>
      <ul className='profile-navigation__list'>
        <li className='profile-navigation__item'>
          <NavLink
            end
            to='/profile'
            className={({ isActive }) =>
              isActive
                ? 'profile-navigation__link profile-navigation__link--active'
                : 'profile-navigation__link'
            }
          >
            About company
          </NavLink>
        </li>
        <li className='profile-navigation__item'>
          <NavLink
            to='/profile/branches'
            className={({ isActive }) =>
              isActive
                ? 'profile-navigation__link profile-navigation__link--active'
                : 'profile-navigation__link'
            }
            activeclassname='profile-navigation__active-link'
          >
            Branches
          </NavLink>
        </li>
        <li className='profile-navigation__item'>
          <NavLink
            to='/profile/photo'
            onClick={(e) => e.preventDefault()}
            className={({ isActive }) =>
              isActive
                ? 'profile-navigation__link profile-navigation__link--disabled profile-navigation__link--active'
                : 'profile-navigation__link profile-navigation__link--disabled'
            }
          >
            Photo
          </NavLink>
        </li>
        <li className='profile-navigation__item'>
          <NavLink
            to='/profile/tariff'
            className={({ isActive }) =>
              isActive
                ? 'profile-navigation__link profile-navigation__link--active'
                : 'profile-navigation__link'
            }
          >
            Tariff
          </NavLink>
        </li>
        <li className='profile-navigation__item'>
          <NavLink
            to='/profile/credentials'
            className={({ isActive }) => {
              return isActive
                ? 'profile-navigation__link profile-navigation__link--active'
                : 'profile-navigation__link'
            }}
          >
            Credentials
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default ProfileNavigation
