import React from 'react'

import './Checkbox.scss'

const Checkbox = ({ label, isChecked, toggleCheckbox, isRequired = false }) => {
  return (
    <div className={`checkbox ${label ? '' : 'checkbox--no-label'}`}>
      <input
        id='a11y-issue-1'
        name='a11y-issues'
        type='checkbox'
        checked={isChecked}
        onChange={toggleCheckbox}
        required={isRequired}
      />
      <label className='checkbox__label' htmlFor='a11y-issue-1'>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
