import React from 'react'

import './Label.scss'

const Label = ({ children, label, text }) => {
  return (
    <div className='label'>
      <label className='label__top'>{label}</label>
      <span className='label__text'>{children}</span>
    </div>
  )
}

export default Label
