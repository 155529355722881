import React from 'react'
import { ReactComponent as Logo } from './images/logo.svg'
import { ReactComponent as EmailIcon } from './images/email.svg'
import { Link } from 'react-router-dom'

import './EmailSent.scss'

const EmailSent = () => {
  return (
    <div className='email-sent'>
      <Logo />
      <EmailIcon className='email-sent__lock-icon' />
      <h3 className='email-sent__title'>Check your mail</h3>
      <span className='email-sent__description'>
        We have sent a password recover instructions to your email
      </span>
      <Link to='/sign-in' className='email-sent__link'>
        Back to Login
      </Link>
      <span className='email-sent__change-email'>
        Did not receive the email? Check your spam filter,{' '}
        <Link to='/forgot-password' className='email-sent__change-email-link'>
          or try another email adress
        </Link>
      </span>
    </div>
  )
}

export default EmailSent
