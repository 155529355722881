import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowIcon } from './images/arrow.svg'
import { ReactComponent as ProfileIcon } from './images/profile.svg'
import { ReactComponent as SupportIcon } from './images/support.svg'
import { ReactComponent as InfoIcon } from './images/info.svg'
import { ReactComponent as LogoutIcon } from './images/logout.svg'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { setCompany } from '../../../../store/companySlice'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie } from '../../../../hooks/cookies'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

import './ProfileDropdown.scss'
import LogoutModal from './LogoutModal/LogoutModal'

const ProfileDropdown = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const company = useSelector((state) => state.company.company)

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
  }
  const ref = useDetectClickOutside({ onTriggered: handleCloseMenu })

  const getCompanyInfo = async () => {
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_company_info/${getCookie(
          'userId'
        )}`,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
      dispatch(setCompany(data))
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLogoutModal = () => {
    setIsLogoutModalOpen(true)
  }

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false)
  }

  const handleLogout = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('userToken')
    navigate('/sign-in')
  }

  useEffect(() => {
    if (!company.id) {
      getCompanyInfo()
    }
  }, [])

  return (
    <div
      className={`profile-dropdown ${
        isMenuOpen ? 'profile-dropdown--isOpened' : ''
      }`}
      ref={ref}
    >
      <button className='profile-dropdown__menu' onClick={handleToggleMenu}>
        <img
          className='profile-dropdown__avatar'
          src={
            company.logo_path
              ? `https://api.travelingo.pro${company.logo_path}`
              : require('./images/photo.png')
          }
          alt='profile avatar'
        />
        <span className='profile-dropdown__name'>{company.company_name}</span>
        <ArrowIcon className='profile-dropdown__arrow' />
      </button>
      {isMenuOpen ? (
        <div className='profile-dropdown__dropdown'>
          <ul className='profile-dropdown__dropdown-links'>
            <li className='profile-dropdown__dropdown-item'>
              <Link to='/profile' className='profile-dropdown__dropdown-link'>
                <ProfileIcon />
                <span className='profile-dropdown__link-text'>
                  Company Profile
                </span>
              </Link>
            </li>
            <li className='profile-dropdown__dropdown-item'>
              <Link className='profile-dropdown__dropdown-link'>
                <SupportIcon />
                <span className='profile-dropdown__link-text'>Help Desk</span>
              </Link>
            </li>
            <li className='profile-dropdown__dropdown-item'>
              <Link className='profile-dropdown__dropdown-link'>
                <InfoIcon />
                <span className='profile-dropdown__link-text'>Info Center</span>
              </Link>
            </li>
            <li className='profile-dropdown__dropdown-item'>
              <button
                className='profile-dropdown__dropdown-link profile-dropdown__dropdown-button'
                onClick={handleOpenLogoutModal}
              >
                <LogoutIcon />
                <span className='profile-dropdown__link-text'>Log Out</span>
              </button>
            </li>
          </ul>
          <ul className='profile-dropdown__dropdown-terms'>
            <li className='profile-dropdown__terms-item'>
              <Link className='profile-dropdown__terms-link'>
                Privacy Policy
              </Link>
            </li>
            <li className='profile-dropdown__terms-item'>
              <Link className='profile-dropdown__terms-link'>
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        ''
      )}
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={handleCloseLogoutModal}
        onSubmit={handleLogout}
      />
    </div>
  )
}

export default ProfileDropdown
