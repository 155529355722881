import ProfileSubheader from '../../../Shared/ProfileSubheader/ProfileSubheader'
import { ReactComponent as Logo } from './images/logo.svg'
import RowInput from '../../../Shared/RowInput/RowInput'
import Input from '../../../Shared/Input/Input'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import React from 'react'

import './Credentials.scss'
import { pageTitles } from '../../../../static/constants/pageTitles'

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
  },
  content: {
    padding: '48px 105px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    width: '287px',
    height: 'fit-content',
    borderRadius: '16px',
  },
}

const Credentials = () => {
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState(false)
  const [isEditEmailOpen, setIsEditEmailOpen] = useState(false)

  const handleOpenPasswordEdit = () => {
    setIsEditPasswordOpen(true)
  }

  const handleClosePasswordEdit = () => {
    setIsEditPasswordOpen(false)
  }

  const handleOpenEmailEdit = () => {
    setIsEditEmailOpen(true)
  }

  const handleCloseEmailEdit = () => {
    setIsEditEmailOpen(false)
  }

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.CREDENTIALS}`
  }, [])

  return (
    <div className='credentials'>
      <ProfileSubheader
        title='Credentials'
        description='Here you can change your credentials'
      />
      <div className='credentials__inputs'>
        <div className='credentials__input-button'>
          <RowInput
            name='Email Login'
            description='You can change your login'
          />
          <button className='credentials__button' onClick={handleOpenEmailEdit}>
            Change Email
          </button>
        </div>
        <div className='credentials__input-button'>
          <RowInput
            name='Password'
            description='You can change your password'
          />
          <button
            className='credentials__button'
            onClick={handleOpenPasswordEdit}
          >
            Change Password
          </button>
        </div>
      </div>
      <Modal
        style={customStyles}
        isOpen={isEditPasswordOpen}
        onRequestClose={handleClosePasswordEdit}
      >
        <Logo />
        <h3 className='modal__title-edit'>Change Password</h3>
        <p className='modal__description-edit'>
          We'll send you reset instructions
        </p>
        <div className='modal__description-input'>
          <Input label='Your Email' placeholder='hilton-park@gmail.com' />
        </div>
        <div className='modal__controls'>
          <button className='modal__button-send'>Send</button>
          <button
            className='modal__button-cancel'
            onClick={handleClosePasswordEdit}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        style={customStyles}
        isOpen={isEditEmailOpen}
        onRequestClose={handleClosePasswordEdit}
      >
        <Logo />
        <h3 className='modal__title-edit'>Change Email</h3>
        <p className='modal__description-edit'>
          We'll send you change instructions
        </p>
        <div className='modal__description-input'>
          <Input label='Your Email' placeholder='hilton-park@gmail.com' />
        </div>
        <div className='modal__controls'>
          <button className='modal__button-send'>Send</button>
          <button
            className='modal__button-cancel'
            onClick={handleCloseEmailEdit}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default Credentials
