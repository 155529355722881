import { useDetectClickOutside } from 'react-detect-click-outside'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import './CategorySelect.scss'

const CategorySelect = ({ selectedOption, onChange }) => {
  const [categories, setCategories] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const handleDropdownClose = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }
  const ref = useDetectClickOutside({ onTriggered: handleDropdownClose })

  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        'https://api.travelingo.pro/api/vendor/get_categories',
        {
          headers: {
            Authorization: 'Bearer api_for_travelingo',
          },
        }
      )
      setCategories(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <div
      onClick={handleToggleDropdown}
      className='category-select'
      type='button'
      ref={ref}
    >
      {selectedOption?.name || (
        <span className='category-select__placeholder'>-</span>
      )}
      {isOpen ? (
        <ul className='category-select__list'>
          {categories.map((category) => {
            return (
              <li className='category-select__list-item' key={category.id}>
                <button
                  onClick={() => {
                    onChange(category)
                    setIsOpen(false)
                  }}
                  className='category-select__button'
                  type='button'
                >
                  {category.name}
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default React.memo(CategorySelect)
