import React, { useEffect } from 'react'
import CashierList from '../CashierList/CashierList'
import { getCookie } from '../../../../../hooks/cookies'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setCashiers } from '../../../../../store/cashiersSlice'

import './CashiersPage.scss'

const CashiersPage = () => {
  const company = useSelector((state) => state.company.company)
  const dispatch = useDispatch()

  const getCashiers = async () => {
    try {
      const { data } = await axios.get(
        'https://api.travelingo.pro/api/vendor/get_cashiers',
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      dispatch(setCashiers(data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (company.id) {
      getCashiers()
    }
  }, [company.id])

  return (
    <div className='cashiers-page'>
      <CashierList />
    </div>
  )
}

export default CashiersPage
