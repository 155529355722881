import React from 'react'
import { ReactComponent as ErrorIcon } from './images/exclamation.svg'

import './Input.scss'

const Input = ({
  value,
  onChange,
  isRequired,
  label,
  placeholder,
  type,
  errorMessage,
  pattern,
  disabled,
}) => {
  return (
    <label className='input'>
      <span className='input__label'>
        {label}
        <span className='input__required-star'>{isRequired ? '*' : ''}</span>
      </span>
      <input
        required={isRequired}
        className='input__input'
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        disabled={disabled}
      />
      {errorMessage ? (
        <div className='input__error-message'>
          <ErrorIcon />
          <span className='input__error-text'>{errorMessage}</span>
        </div>
      ) : (
        ''
      )}
    </label>
  )
}

export default Input
