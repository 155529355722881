import React from 'react'
import { ReactComponent as EmptyImage } from './images/empty-image.svg'

import './ImageInput.scss'

const ImageInput = ({
  image,
  value,
  localPictureLink,
  onChange,
  clearPicture,
}) => {
  console.log(localPictureLink)
  return (
    <div className='image-input'>
      {localPictureLink || image ? (
        <img
          className='image-input__logo'
          src={
            localPictureLink
              ? localPictureLink
              : `https://api.travelingo.pro${image}`
          }
          alt='Company'
        />
      ) : (
        <EmptyImage className='image-input__image' />
      )}
      <span className='image-input__description'>
        {value?.name ? (
          value.name
        ) : (
          <>
            <span className='image-input__upload-link'>Click to upload</span> or
            drag and drop SVG, PNG or JPG (max 1200x1200 px)
          </>
        )}
      </span>
      <input
        onChange={onChange}
        onClick={(event) => {
          event.target.value = null
        }}
        className='image-input__input'
        type='file'
        accept='image/png, image/jpeg, image/jpg'
      />
    </div>
  )
}

export default ImageInput
