import { createSlice } from '@reduxjs/toolkit'

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {
      id: 0,
      token: 0,
    },
  },
  reducers: {
    setCompany: (state, action) => {
      state.company = { ...action.payload }
    },
  },
})

export const { setCompany } = companySlice.actions

export default companySlice.reducer
