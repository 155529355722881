import React from 'react'

import './ProfileSubheader.scss'

const ProfileSubheader = ({ children, title, description }) => {
  return (
    <div className='profile-subheader'>
      <div className='profile-subheader__left'>
        <h4 className='profile-subheader__header'>{title}</h4>
        <span className='profile-subheader__subtitle'>{description}</span>
      </div>
      {children}
    </div>
  )
}

export default ProfileSubheader
