import React from 'react'

import './RowInput.scss'

const RowInput = ({
  children,
  name,
  description,
  isRequired,
  placeholder,
  value,
  onChange,
  pattern,
}) => {
  return (
    <div className='row-input'>
      <div className='row-input__label'>
        <span className='row-input__name'>
          {name}
          {isRequired ? <span className='row-input__required'>*</span> : ''}
        </span>
        <span className='row-input__description'>{description}</span>
      </div>
      {children ? (
        children
      ) : (
        <input
          className='row-input__input'
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={isRequired}
          pattern={pattern}
        />
      )}
    </div>
  )
}

export default RowInput
