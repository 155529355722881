import React, { useEffect } from 'react'
import ProfileSubheader from '../../../Shared/ProfileSubheader/ProfileSubheader'
import { pageTitles } from '../../../../static/constants/pageTitles'
import FormButton from '../../../Shared/FormButton/FormButton'
import TariffSelect from './TariffSelect/TariffSelect'
import TariffMethods from './TariffMethods/TariffMethods'

import './Tariff.scss'

const Tariff = () => {
  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.TARIFF}`
  }, [])

  return (
    <div className='tariff'>
      <ProfileSubheader
        title='Tariff'
        description='All information about the tariff, change and payment'
      >
        <FormButton label='Make Payment' />
      </ProfileSubheader>
      <TariffSelect />
      <div className='tariff__payment-methods'>
        <TariffMethods />
      </div>
    </div>
  )
}

export default Tariff
