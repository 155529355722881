import React from 'react'

import './LoadingCover.scss'

const LoadingCover = () => {
  return (
    <div className='loading-cover'>
      <div className='loading-cover__loader' />
    </div>
  )
}

export default LoadingCover
