import React from 'react'

import './CustomButton.scss'

export const BUTTON_STYLES = {
  SUBMIT: 'SUBMIT',
  EMPTY: 'EMPTY',
}

const CustomButton = ({
  label,
  disabled,
  onClick,
  isLoading,
  style = BUTTON_STYLES.SUBMIT,
  type,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`custom-button ${
        style === BUTTON_STYLES.SUBMIT
          ? 'custom-button--submit'
          : style === BUTTON_STYLES.EMPTY
          ? 'custom-button--empty'
          : ''
      }`}
      onClick={onClick}
    >
      {isLoading ? <div className='custom-button__loader' /> : label}
    </button>
  )
}

export default CustomButton
