import React from 'react'
import Input from '../../../../Shared/Input/Input'
import CustomButton from '../../../../Shared/CustomButton/CustomButton'
import CategorySelect from '../../../../Shared/CategorySelect/CategorySelect'

import './CompanyForm.scss'

const CompanyForm = ({
  companyName,
  tagline,
  category,
  address,
  phoneNumber,
  isLoading,
  setCompanyName,
  setTagline,
  setCategory,
  setAddress,
  setPhoneNumber,
  onSubmit,
}) => {
  return (
    <form className='company-form' onSubmit={onSubmit}>
      <div className='company-form__inputs'>
        <div className='company-form__input-wrapper'>
          <Input
            label='Company name for clients'
            placeholder='London Hills'
            value={companyName}
            onChange={setCompanyName}
            isRequired
          />
          <span className='company-form__underlabel'>
            Specified without a legal form
          </span>
        </div>
        <div className='company-form__input-wrapper'>
          <Input
            label='Tagline'
            placeholder='LH'
            value={tagline}
            onChange={setTagline}
          />
          <span className='company-form__underlabel'>
            A quick snapshot of your company
          </span>
        </div>
        <div className='company-form__input-wrapper'>
          <span className='company-form__category-label'>
            Category <span className='company-form__category-required'>*</span>
          </span>
          <CategorySelect selectedOption={category} onChange={setCategory} />
        </div>
        <div className='company-form__input-wrapper'>
          <Input label='Main address' value={address} onChange={setAddress} />
          <span className='company-form__underlabel'>
            You will be able to add addresses after registration is completed
          </span>
        </div>
        <div className='company-form__input-wrapper'>
          <Input
            label='Phone number'
            placeholder='+ 123 456 789'
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <span className='company-form__underlabel'>
            A quick snapshot of your company
          </span>
        </div>
      </div>
      <div className='company-form__submit'>
        <CustomButton isLoading={isLoading} label='Create Company' />
      </div>
    </form>
  )
}

export default CompanyForm
