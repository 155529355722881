import React from 'react'
import { ReactComponent as Logo } from './images/logo.svg'
import { ReactComponent as CompanyInfoIcon } from './images/company-info.svg'
import { ReactComponent as DashboardIcon } from './images/dashboard.svg'
import { ReactComponent as StatisticsIcon } from './images/statistics.svg'
import { ReactComponent as TransactionsIcon } from './images/transactions.svg'
import { ReactComponent as StaffIcon } from './images/staff.svg'
import { ReactComponent as ClientsIcon } from './images/clients.svg'
import { ReactComponent as FeedbackIcon } from './images/feedback.svg'
import { ReactComponent as MessagesIcon } from './images/messages.svg'
import { NavLink, useLocation } from 'react-router-dom'

import './NavBar.scss'

const NavBar = () => {
  const location = useLocation()

  return (
    <nav className='navbar'>
      <div className='navbar__logo-wrapper'>
        <Logo className='navbar__logo' />
      </div>
      <ul className='navbar__links'>
        {location.pathname.split('/')[1] === 'company-info' ? (
          <li>
            <NavLink
              to='/company-info'
              className={({ isActive }) =>
                isActive ? 'navbar__link navbar__link--active' : 'navbar__link'
              }
            >
              <div className='navbar__link-text-wrapper'>
                <CompanyInfoIcon />
                <span className='navbar__link-text'>Company info</span>
              </div>
              <div className='navbar__link-circle-active' />
            </NavLink>
          </li>
        ) : (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <DashboardIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Dashboard</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/statistics'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <StatisticsIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Statistics</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/transactions'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <TransactionsIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Transactions</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/staff'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <StaffIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Staff</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/clients'
                onClick={(e) => e.preventDefault()}
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--inactive navbar__link--active'
                    : 'navbar__link navbar__link--inactive'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <ClientsIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Clients</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/feedback'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <FeedbackIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Feedback</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/messages'
                className={({ isActive }) =>
                  isActive
                    ? 'navbar__link navbar__link--active'
                    : 'navbar__link'
                }
              >
                <div className='navbar__link-text-wrapper'>
                  <MessagesIcon className='navbar__link-icon' />
                  <span className='navbar__link-text'>Messages</span>
                </div>
                <div className='navbar__link-circle-active' />
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}

export default NavBar
