import React from 'react'

import './Toggle.scss'

const Toggle = ({ isActive, onToggle }) => {
  return (
    <button
      className={`toggle ${isActive ? 'toggle--active' : ''}`}
      onClick={onToggle}
    >
      <div className='toggle__thumb'></div>
    </button>
  )
}

export default Toggle
