import React from 'react'
import { ReactComponent as Logo } from './images/logo.svg'

import './SignHero.scss'

const SignHero = () => {
  return (
    <div className='sign-hero'>
      <Logo />
      <span className='sign-hero__title'>
        Personal account with convenient functionality
      </span>
      <p className='sign-hero__description'>
        for tracking statistics and many other operations
      </p>
      <div className='sign-hero__images'>
        <img
          className='sign-hero__image1'
          src={require('./images/hero1.png')}
          alt='hero 1'
        />
        <img
          className='sign-hero__image2'
          src={require('./images/hero2.jpg')}
          alt='hero 2'
        />
      </div>
    </div>
  )
}

export default SignHero
