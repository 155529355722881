import React from 'react'

import './TariffMethods.scss'

const TariffMethods = () => {
  return (
    <div className='tariff-methods'>
      <div className='tariff-methods__description'>
        <span className='tariff-methods__title'>Payment methods</span>
        <span className='tariff-methods__subtitle'>
          Make the payment before the next debit
        </span>
      </div>
      <button className='tariff-methods__add-method'>
        + New payment method
      </button>
    </div>
  )
}

export default TariffMethods
