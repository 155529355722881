import React from 'react'

import './ColorPicker.scss'

const ColorPicker = ({
  value,
  onChange,
  isRequired,
  label,
  placeholder,
  type,
  pattern,
  disabled,
}) => {
  return (
    <label className='color-picker'>
      <span className='color-picker__label'>
        {label}
        <span className='color-picker__required-star'>
          {isRequired ? '*' : ''}
        </span>
      </span>
      <input
        required={isRequired}
        className='color-picker__input'
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        disabled={disabled}
      />
    </label>
  )
}

export default ColorPicker
