import React from 'react'

import './CashierQr.scss'

const CashierQr = () => {
  return (
    <div className='cashier-qr'>
      <span className='cashier-qr__title'>QR-code</span>
      <span className='cashier-qr__subtitle'>for invite</span>
      <img
        className='cashier-qr__code'
        src={require('./images/qr.png')}
        alt=''
      />
    </div>
  )
}

export default CashierQr
