import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      id: 0,
      token: 0,
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = { ...action.payload }
    },
  },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
