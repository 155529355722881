import CashierProfile from './HomePage/Home/Staff/CashierProfile/CashierProfile'
import CreateCashier from './HomePage/Home/Staff/CreateCashier/CreateCashier'
import CashiersPage from './HomePage/Home/Staff/CashiersPage/CashiersPage'
import AboutCompany from './HomePage/Profile/AboutCompany/AboutCompany'
import Credentials from './HomePage/Profile/Credentials/Credentials'
import ForgotPassword from './Login/ForgotPassword/ForgotPassword'
import CompanyInfo from './HomePage/Home/CompanyInfo/CompanyInfo'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Branches from './HomePage/Profile/Branches/Branches'
import SignUpSent from './Login/SignUpSent/SignUpSent'
import Tariff from './HomePage/Profile/Tariff/Tariff'
import EmailSent from './Login/EmailSent/EmailSent'
import Profile from './HomePage/Profile/Profile'
import Staff from './HomePage/Home/Staff/Staff'
import { setUser } from '../store/userSlice'
import { getCookie } from '../hooks/cookies'
import { useState, useEffect } from 'react'
import NavBar from './Shared/NavBar/NavBar'
import NotFound from './NotFound/NotFound'
import SignIn from './Login/SignIn/SignIn'
import SignUp from './Login/SignUp/SignUp'
import { useDispatch, useSelector } from 'react-redux'
import Home from './HomePage/Home/Home'

import './App.scss'
import CashierEdit from './HomePage/Home/Staff/CashierProfile/CashierEdit/CashierEdit'

const unsafeRoutes = [
  '/sign-in',
  '/sign-up',
  '/sign-up/confirmation-sent',
  '/forgot-password',
  '/forgot-password/confirmation-sent',
]

function App() {
  const [isUserLogged, setIsUserLogged] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const isRouteUnsafe = unsafeRoutes.includes(location.pathname)
  const user = useSelector((state) => state.user.user)

  const checkCookie = async () => {
    if (getCookie('userToken')) {
      setIsUserLogged(true)
      dispatch(
        setUser({
          ...user,
          user_id: getCookie('userId'),
          token_api: getCookie('userToken'),
        })
      )
    } else {
      setIsUserLogged(false)
    }
  }

  useEffect(() => {
    checkCookie()
  }, [location.pathname])

  return (
    <div className='app'>
      <div className='app__safe-routes'>
        {!isRouteUnsafe ? <NavBar /> : ''}
        <Routes>
          <Route path='/' element={<Home />}>
            <Route element={<CompanyInfo />} />
            <Route path='company-info' element={<CompanyInfo />} />
            <Route
              path='/'
              element={
                <Navigate to={isUserLogged ? 'profile' : 'sign-in'} replace />
              }
            />
            <Route path='profile' element={<Profile />}>
              <Route index element={<AboutCompany />} />
              <Route path='branches' element={<Branches />} />
              <Route path='tariff' element={<Tariff />} />
              <Route path='credentials' element={<Credentials />} />
            </Route>
            <Route path='staff' element={<Staff />}>
              <Route index element={<CashiersPage />} />
              <Route path='new-cashier' element={<CreateCashier />} />
              <Route path=':id' element={<CashierProfile />} />
              <Route path=':id/edit' element={<CashierEdit />} />
            </Route>
          </Route>
        </Routes>
      </div>
      <div className='app__unsafe-routes'>
        <Routes>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/sign-up/confirmation-sent' element={<SignUpSent />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route
            path='/forgot-password/confirmation-sent'
            element={<EmailSent />}
          />
        </Routes>
      </div>
    </div>
  )
}

export default App
