import React from 'react'

import './MinimalInput.scss'

const MinimalInput = ({ value, onChange, placeholder, disabled }) => {
  return (
    <input
      className='minimal-input'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default MinimalInput
