export const pageTitles = {
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  FORGOT_PASSWORD: 'Forgot Password',
  PROFILE: 'Company Profile',
  BRANCHES: 'Branches',
  TARIFF: 'Tariff',
  CREDENTIALS: 'Credentials',
  DASHBOARD: 'Dashboard',
  STATISTICS: 'Statistics',
  TRANSACTIONS: 'Transactions',
  STAFF: 'Staff',
  FEEDBACK: 'Feedback',
  MESSAGES: 'Messages',
  NOT_FOUND: '404',
  COMPANY_INFO: 'Company Info',
}
