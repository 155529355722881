import React, { useEffect, useState } from 'react'
import ProfileSubheader from '../../../Shared/ProfileSubheader/ProfileSubheader'
import { ReactComponent as PreviewImage } from './images/preview.svg'
import ImageInput from '../../../Shared/ImageInput/ImageInput'
import RowInput from '../../../Shared/RowInput/RowInput'
import { TagsInput } from 'react-tag-input-component'
import Input from '../../../Shared/Input/Input'
import SocialInputs from './SocialInputs/SocialInputs'
import CategorySelect from '../../../Shared/CategorySelect/CategorySelect'
import { getCookie } from '../../../../hooks/cookies'
import LoadingCover from '../../../Shared/LoadingCover/LoadingCover'
import { pageTitles } from '../../../../static/constants/pageTitles'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import './AboutCompany.scss'
import { setCompany } from '../../../../store/companySlice'

const AboutCompany = () => {
  const [tags, setTags] = useState([])
  const [cashback, setCashback] = useState(null)
  const [companyName, setCompanyName] = useState('')
  const [tagline, setTagline] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState({})
  const [picture, setPicture] = useState('')
  const [pictureLink, setPictureLink] = useState('')
  const [localPictureLink, setLocalPictureLink] = useState(null)
  const dispatch = useDispatch()

  const [telegram, setTelegram] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [facebook, setFacebook] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const company = useSelector((state) => state.company.company)

  const clearPicture = () => {
    setPicture(null)
    setLocalPictureLink(null)
  }

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLocalPictureLink(URL.createObjectURL(event.target.files[0]))
    }
    setPicture(event.target.files[0])
  }

  const handleChangeCategory = (category) => {
    setCategory(category)
  }

  const handleChangeCashback = (event) => {
    setCashback(event.target.value)
  }

  const handleChangeCompanyName = (event) => {
    setCompanyName(event.target.value)
  }

  const handleChangeTagline = (event) => {
    setTagline(event.target.value)
  }

  const handleChangePhone = (event) => {
    setPhoneNumber(event.target.value)
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleChangeDescription = (event) => {
    setDescription(event.target.value)
  }

  const handleChangeFacebook = (event) => {
    setFacebook(event.target.value)
  }

  const handleChangeWhatsapp = (event) => {
    setWhatsapp(event.target.value)
  }

  const handleChangeTelegram = (event) => {
    setTelegram(event.target.value)
  }

  const setInitialInfo = (data) => {
    setLocalPictureLink('')
    setPicture('')
    setCashback(Number(data.cashback_percent))
    setCategory(data.category_info)
    setCompanyName(data.company_name)
    setTagline(data.tagline)
    setPhoneNumber(data.phone)
    setTags(data.keywords ? data.keywords.split(',') : [])
    setEmail(data.email === null ? '' : data.email)
    setPictureLink(data.logo_path)
    setDescription(data.description === null ? '' : data.description)
    setTelegram(data.telegram === null ? '' : data.telegram)
    setWhatsapp(data.whatsapp === null ? '' : data.whatsapp)
    setFacebook(data.facebook === null ? '' : data.facebook)
  }

  const resetChanges = () => {
    setInitialInfo(company)
  }

  const handleSubmitCompanyInfo = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('company_id', company.id)
      formData.append('company_name', companyName)
      formData.append('tagline', tagline)
      formData.append('category_id', category.id)
      formData.append('email', email)
      formData.append('logo', picture)
      formData.append('keywords', tags.toString())
      formData.append('description', description)
      formData.append('cashback_percent', cashback)
      formData.append('whatsapp', whatsapp)
      formData.append('facebook', facebook)
      formData.append('telegram', telegram)
      const { data } = await axios.post(
        'https://api.travelingo.pro/api/vendor/update_company',
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
      dispatch(
        setCompany({
          ...company,
          cashback_percent: cashback,
          company_name: companyName,
          tagline: tagline,
          logo_path: data.logo_path,
          description: description,
          whatsapp: whatsapp,
          facebook: facebook,
          telegram: telegram,
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setInitialInfo(company)
  }, [company])

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.PROFILE}`
  }, [])

  return (
    <form className='about-company' onSubmit={handleSubmitCompanyInfo}>
      <ProfileSubheader
        title='About company'
        description='Update your company logo and details here'
      >
        <div className='about-company__buttons'>
          <button
            className='about-company__button-cancel'
            type='button'
            onClick={resetChanges}
          >
            Cancel
          </button>
          <button className='about-company__button-save' type='submit'>
            Save
          </button>
        </div>
      </ProfileSubheader>
      <div className='about-company__form'>
        <div className='about-company__top-inputs'>
          <div className='about-company__category-wrapper'>
            <span className='about-company__category-text'>Category</span>
            <CategorySelect
              selectedOption={category}
              onChange={handleChangeCategory}
            />
          </div>
          {/* <CustomSelect
            label='Currency'
            options={['USD', 'BYN', 'RUB']}
            isRequired
            withEmpty
          /> */}
          <Input
            label='Cashback (%)'
            value={cashback}
            onChange={handleChangeCashback}
            isRequired
            type='number'
          />
        </div>
        <div className='about-company__bottom-wrapper'>
          <div className='about-company__inputs'>
            <RowInput
              value={companyName}
              onChange={handleChangeCompanyName}
              name='Public name'
              description='This will be displayed on your profile'
              isRequired
            />
            <RowInput
              name='Tagline'
              description='A quick snapshot of your company'
              value={tagline}
              onChange={handleChangeTagline}
            />
            <RowInput
              name='Company logo'
              description='Update your company logo'
            >
              <ImageInput
                image={pictureLink}
                localPictureLink={localPictureLink}
                value={picture}
                onChange={handleChangeImage}
                clearPicture={clearPicture}
              />
            </RowInput>
            <RowInput
              name='Phone number'
              description='For clients'
              placeholder='+ 123 456 789'
              value={phoneNumber}
              onChange={handleChangePhone}
              type='phone'
            />
            <RowInput
              name='Email'
              description='For clients'
              placeholder='john.adams@gmail.com'
              value={email}
              onChange={handleChangeEmail}
            />
            <RowInput
              name='Description'
              description='Detailed description of the company without addresses and contacts'
              value={description}
              onChange={handleChangeDescription}
            />
            <RowInput
              name='Keywords'
              description='To search for your company in the app'
            >
              <TagsInput
                classNames={{
                  input: 'about-company__tags-input',
                  tag: 'about-company__tag',
                }}
                value={tags}
                onChange={setTags}
              />
            </RowInput>
            <RowInput
              name='Social profiles'
              description='Add your social media'
            >
              <SocialInputs
                whatsapp={whatsapp}
                telegram={telegram}
                facebook={facebook}
                setWhatsapp={handleChangeWhatsapp}
                setTelegram={handleChangeTelegram}
                setFacebook={handleChangeFacebook}
              />
            </RowInput>
          </div>
          <PreviewImage />
        </div>
      </div>
      {isLoading && <LoadingCover />}
    </form>
  )
}

export default AboutCompany
