import React, { useEffect, useState } from 'react'
import LoadingBranchCover from '../LoadingBranchCover/LoadingBranchCover'
import MinimalInput from '../../../../Shared/MinimalInput/MinimalInput'
import DeletePointModal from './DeletePointModal/DeletePointModal'
import { ReactComponent as MinusIcon } from './images/minus.svg'
import { ReactComponent as PlusIcon } from './images/plus.svg'
import AddressPricing from './AddressPricing/AddressPricing'
import { getCookie } from '../../../../../hooks/cookies'
import AddressBadge from './AddressBadge/AddressBadge'
import AddressInfo from './AddressInfo/AddressInfo'
import Input from '../../../../Shared/Input/Input'
import { useSelector } from 'react-redux'
import axios from 'axios'

import './AddressBlock.scss'
import ErrorMessage from '../../../../Shared/ErrorMessage/ErrorMessage'
import { phoneRegex } from '../../../../../hooks/regex'

const AddressBlock = ({
  id,
  adressNumber,
  address,
  cashbackPercent,
  minTransactionAmount,
  maxTransactionAmount,
  phone,
  color,
  deletePoint,
  walletAddress,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pointAddress, setPointAddress] = useState(address)
  const [cashback, setCashback] = useState(cashbackPercent)
  const [minAmount, setMinAmount] = useState(minTransactionAmount)
  const [maxAmount, setMaxAmount] = useState(maxTransactionAmount)
  const [phoneNumber, setPhoneNumber] = useState(phone)
  const [pointColor, setPointColor] = useState(color)
  const [inputsState, setInputsState] = useState({
    minAmount: !!minTransactionAmount,
    maxAmount: !!maxTransactionAmount,
    phoneNumber: !!phone,
  })
  const company = useSelector((state) => state.company.company)
  const [isDeleteModalOpen, setIsModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [walletData, setWalletData] = useState({})

  const handleOpenDeleteModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setIsModalOpen(false)
  }

  const handleChangeAddress = (event) => {
    setPointAddress(event.target.value)
  }
  const handleChangeColor = (event) => {
    setPointColor(event.target.value)
  }

  const handleChangeCashback = (event) => {
    setCashback(event.target.value)
  }

  const handleAddMinAmountInput = () => {
    setInputsState({ ...inputsState, minAmount: true })
  }

  const handleAddMaxAmountInput = () => {
    setInputsState({ ...inputsState, maxAmount: true })
  }

  const handleAddPhoneInput = () => {
    setInputsState({ ...inputsState, phone: true })
  }

  const handleOpenEditing = () => {
    setIsEditing(true)
  }

  const handleCloseEditing = (event) => {
    event.preventDefault()
    setIsEditing(false)
  }

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleChangeMinAmount = (event) => {
    setMinAmount(event.target.value)
  }

  const handleChangeMaxAmount = (event) => {
    setMaxAmount(event.target.value)
  }

  const handleChangePhone = (event) => {
    setPhoneNumber(event.target.value)
  }

  const getWalletData = async () => {
    try {
      const { data } = await axios.get(
        'https://sc.travelingo.pro/api/get_wallet_info',
        {
          params: {
            wallet_address: walletAddress,
          },
        }
      )
      if (data.status === 'ok') {
        setWalletData(data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitEdit = async (event) => {
    setErrorMessage('')
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('address', pointAddress)
      formData.append('company_id', company.id)
      formData.append('cashback_percent', cashback)
      formData.append('max_amount', maxAmount)
      formData.append('min_amount', minAmount)
      formData.append('phone', phoneNumber)
      formData.append('color_tag', pointColor)
      formData.append('point_id', id)
      await axios.post(
        `https://api.travelingo.pro/api/vendor/update_point`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
          },
        }
      )
      setIsEditing(false)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getWalletData()
  }, [])

  return (
    <div className='address-block'>
      <div className='address-block__pricing'>
        <AddressBadge
          color={pointColor}
          label={'Address ' + adressNumber}
          onChange={handleChangeColor}
          disabled={!isEditing}
        />
        <div className='address-block__pricing-wrapper'>
          <AddressPricing walletInfo={walletData} />
        </div>
      </div>
      <form className='address-block__detailed' onSubmit={handleSubmitEdit}>
        <div className='address-block__inputs-wrapper'>
          <AddressInfo
            disabled={!isEditing}
            address={pointAddress}
            onChange={handleChangeAddress}
          />
          {isExpanded ? (
            <>
              {/* <div className='address-block__minimal-input-wrapper'>
                <MinimalInput value='UQDe0Nop97tsonN5S8ncMrlUriIAwUacvfDt9rE9qIJgW6ro' />
              </div> */}
              <div className='address-block__input-wrapper'>
                <Input
                  disabled={!isEditing}
                  value={cashback}
                  onChange={handleChangeCashback}
                  label='Cashback'
                  isRequired
                />
              </div>
              {inputsState.minAmount ? (
                <div className='address-block__input-add-wrapper'>
                  <Input
                    label='Min transaction amount'
                    value={minAmount}
                    onChange={handleChangeMinAmount}
                    type='number'
                    disabled={!isEditing}
                  />
                </div>
              ) : isEditing ? (
                <button
                  className='address-block__empty-add-button'
                  onClick={handleAddMinAmountInput}
                >
                  + Minimum transaction amount
                </button>
              ) : (
                ''
              )}
              {inputsState.maxAmount ? (
                <div className='address-block__input-add-wrapper'>
                  <Input
                    label='Max transaction amount'
                    value={maxAmount}
                    onChange={handleChangeMaxAmount}
                    type='number'
                    disabled={!isEditing}
                  />
                </div>
              ) : isEditing ? (
                <button
                  className='address-block__empty-add-button'
                  onClick={handleAddMaxAmountInput}
                >
                  + Maximum transaction amount
                </button>
              ) : (
                ''
              )}
              {inputsState.phoneNumber ? (
                <div className='address-block__input-add-wrapper'>
                  <Input
                    label='Phone number'
                    value={phoneNumber}
                    onChange={handleChangePhone}
                    placeholder='+123 456 789'
                    pattern={phoneRegex.source}
                    disabled={!isEditing}
                  />
                </div>
              ) : isEditing ? (
                <button
                  className='address-block__empty-add-button'
                  onClick={handleAddPhoneInput}
                >
                  + Phone number
                </button>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          <div className='address-block__error-wrapper'>
            <ErrorMessage message={errorMessage} />
          </div>
        </div>
        <div className='address-block__buttons'>
          {isEditing ? (
            <button
              className='address-block__edit'
              onClick={handleCloseEditing}
              type='button'
            >
              Cancel
            </button>
          ) : (
            <button
              className='address-block__edit'
              onClick={() => {
                setIsExpanded(true)
                handleOpenEditing()
              }}
            >
              Edit
            </button>
          )}
          {isEditing ? (
            <button className='address-block__save' type='submit'>
              Save
            </button>
          ) : (
            <button
              className='address-block__delete'
              onClick={handleOpenDeleteModal}
              type='button'
            >
              Delete
            </button>
          )}
        </div>
      </form>
      <div className='address-block__expand'>
        <button
          onClick={handleToggleExpand}
          className='address-block__expand-button'
        >
          {isExpanded ? <MinusIcon /> : <PlusIcon />}
        </button>
      </div>
      {isLoading ? <LoadingBranchCover /> : ''}
      <DeletePointModal
        label={address}
        isLoading={isLoading}
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onSubmit={() => {
          deletePoint(id)
        }}
      />
    </div>
  )
}

export default React.memo(AddressBlock)
