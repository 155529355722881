import React from 'react'
import { ReactComponent as AvailableIcon } from './images/available.svg'
import { ReactComponent as UnvailableIcon } from './images/unavailable.svg'

import './TariffDescription.scss'
import { Link } from 'react-router-dom'

const TariffDescription = () => {
  return (
    <div className='tariff-description'>
      <span className='tariff-description__title'>
        Basic functionality for microbusiness
      </span>
      <span className='tariff-description__subtitle'>
        Loyalty program with basic features:
      </span>
      <ul className='tariff-description__available-list'>
        <li className='tariff-description__available-item'>
          <AvailableIcon />
          <span className='tariff-description__available-text'>
            ready-made mobile application and company website
          </span>
        </li>
        <li className='tariff-description__available-item'>
          <AvailableIcon />
          <span className='tariff-description__available-text'>
            customer base management tools
          </span>
        </li>
        <li className='tariff-description__available-item'>
          <AvailableIcon />
          <span className='tariff-description__available-text'>
            bonus program to create customer loyalty
          </span>
        </li>
        <li className='tariff-description__available-item'>
          <AvailableIcon />
          <span className='tariff-description__available-text'>
            customer feedback
          </span>
        </li>
      </ul>
      <ul className='tariff-description__unavailable-list'>
        <li className='tariff-description__unavailable-item'>
          <UnvailableIcon />
          <span className='tariff-description__unavailable-text'>
            powerful tools for working with analytics and personnel
          </span>
        </li>
        <li className='tariff-description__unavailable-item'>
          <UnvailableIcon />
          <span className='tariff-description__unavailable-text'>
            customer notification functionality
          </span>
        </li>
      </ul>
      <Link className='tariff-description__to-pro'>Go to Pro</Link>
    </div>
  )
}

export default TariffDescription
