import React from 'react'
import Toggle from '../../../../../Shared/Toggle/Toggle'
import Label from '../../../../../Shared/Label/Label'

import './CashierInfo.scss'

const CashierInfo = ({
  name,
  lastName,
  phone,
  email,
  address,
  color,
  isActive,
  toggleIsActive,
}) => {
  return (
    <div className='cashier-info'>
      <span className='cashier-info__name'>
        {name} {lastName}
      </span>
      <div className='cashier-info__toggle-wrapper'>
        <Toggle isActive={isActive} onToggle={toggleIsActive} />
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Login'>{email}</Label>
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Branch'>
          <div className='cashier-info__branch-wrapper'>
            <div
              className='cashier-info__branch-color'
              style={{ backgroundColor: color }}
            />
            <span>{address}</span>
          </div>
        </Label>
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Phone number'>{phone ? phone : 'Empty'}</Label>
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Last transaction'>
          <span>27/07/2023</span>{' '}
          <span className='cashier-info__last-transaction-time'>15:30</span>
        </Label>
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Registration date'>03/03/2023</Label>
      </div>
      <div className='cashier-info__label-wrapper'>
        <Label label='Comments'>-</Label>
      </div>
    </div>
  )
}

export default CashierInfo
