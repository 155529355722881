import React from 'react'

import './TariffBlockInactive.scss'

const TariffBlockInactive = () => {
  return (
    <div className='tariff-block-inactive'>
      <div className='tariff-block-inactive__name'>Pro</div>
      <span className='tariff-block-inactive__price'>50 €/month</span>
    </div>
  )
}

export default TariffBlockInactive
