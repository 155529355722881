import React, { useState } from 'react'
import SignHero from '../../Shared/SignHero/SignHero'
import SignInForm from './SignInForm/SignInForm'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../../store/userSlice'
import { getCookie } from '../../../hooks/cookies'

import './SignIn.scss'
import { pageTitles } from '../../../static/constants/pageTitles'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isRememberChecked, setIsRememberChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user.user)

  const toggleRememberMe = () => {
    setIsRememberChecked(!isRememberChecked)
  }

  const handleEmailInput = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordInput = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      const { data } = await axios.post(
        'https://api.travelingo.pro/api/vendor/auth',
        formData,
        {
          headers: {
            Authorization: 'Bearer api_for_travelingo',
          },
        }
      )
      if (data.status === 'error') {
        setErrorMessage(data.error)
      } else {
        if (isRememberChecked) {
          localStorage.setItem(
            'userCreds',
            JSON.stringify({ email: email, password: password })
          )
        } else {
          localStorage.removeItem('userCreds')
        }
        dispatch(
          setUser({ ...user, userId: data.user_id, userToken: data.token_api })
        )
        localStorage.setItem('userId', data.user_id)
        localStorage.setItem('userToken', data.token_api)
        navigate('/')
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const userCreds = localStorage.getItem('userCreds')
    if (!userCreds) {
      return
    }
    const { email, password } = JSON.parse(localStorage.getItem('userCreds'))
    if (email && password) {
      setEmail(email)
      setPassword(password)
      setIsRememberChecked(true)
    }
  }, [])

  useEffect(() => {
    if (
      typeof Number(getCookie('userId')) === 'number' &&
      Number(getCookie('userId')) === user.userId &&
      typeof getCookie('userToken') === 'string' &&
      getCookie('userToken') === user.userToken
    ) {
      navigate('/company-info')
    }
  }, [user.id])

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.SIGN_IN}`
  }, [])

  return (
    <div className='sign-in'>
      <SignHero />
      <SignInForm
        isLoading={isLoading}
        errorMessage={errorMessage}
        email={email}
        password={password}
        isRememberChecked={isRememberChecked}
        setEmail={handleEmailInput}
        setPassword={handlePasswordInput}
        setIsRememberChecked={toggleRememberMe}
        submitForm={handleSubmitForm}
      />
    </div>
  )
}

export default SignIn
