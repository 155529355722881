import React from 'react'
import Modal from 'react-modal'

import './DeletePointModal.scss'
import CustomButton, {
  BUTTON_STYLES,
} from '../../../../../Shared/CustomButton/CustomButton'

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
    zIndex: 999,
  },
  content: {
    padding: '0 105px 42px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '16px',
  },
}

const DeletePointModal = ({ label, isLoading, isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      className='delete-point-modal'
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <h3 className='delete-point-modal__title'>Delete point</h3>
      <p className='delete-point-modal__description'>
        Are you sure want to delete the point{' '}
        <strong className='delete-point-modal__description-address'>
          {label}
        </strong>
        ?
      </p>
      <div className='delete-point-modal__submit'>
        <CustomButton disabled={isLoading} onClick={onSubmit} label='Delete' />
      </div>
      <div className='delete-point-modal__cancel'>
        <CustomButton
          disabled={isLoading}
          onClick={onClose}
          label='Cancel'
          style={BUTTON_STYLES.EMPTY}
        />
      </div>
    </Modal>
  )
}

export default DeletePointModal
