import React, { useEffect, useState } from 'react'
import { getCookie } from '../../../hooks/cookies'
import { useSelector } from 'react-redux'
import axios from 'axios'

import './BranchSelect.scss'

const BranchSelect = ({ selectedOption, onChange }) => {
  const [points, setPoints] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const company = useSelector((state) => state.company.company)

  const getAllPoints = async () => {
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_point_info`,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      setPoints(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    getAllPoints()
  }, [])

  return (
    <div
      onClick={handleToggleDropdown}
      className='category-select'
      type='button'
    >
      {selectedOption?.address || (
        <span className='category-select__placeholder'>-</span>
      )}
      {isOpen ? (
        <ul className='category-select__list'>
          {points.map((point) => {
            return (
              <li className='category-select__list-item' key={point.id}>
                <button
                  onClick={() => {
                    onChange(point)
                    setIsOpen(false)
                  }}
                  className='category-select__button'
                  type='button'
                >
                  <div
                    className='branch-select__color'
                    style={{ backgroundColor: point.color_tag }}
                  />
                  <span className='branch-select__address'>
                    {point.address}
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default React.memo(BranchSelect)
