import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ProfileDropdown from './ProfileDropdown/ProfileDropdown'
import { ReactComponent as TrvIcon } from './images/trv-currency.svg'
import axios from 'axios'
import { useSelector } from 'react-redux'

import './Header.scss'

const Header = ({ title, isBalanceHidden }) => {
  const user = useSelector((state) => state.user.user)
  const [wallet, setWallet] = useState({})

  const getWalletData = async () => {
    try {
      const { data } = await axios.get(
        'https://sc.travelingo.pro/api/get_wallet_info',
        {
          params: {
            wallet_address: user.wallet_info?.address,
          },
        }
      )
      if (data.status === 'ok') {
        setWallet(data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user.wallet_info?.address) {
      getWalletData()
    }
  }, [])

  return (
    <header className='header'>
      <h2 className='header__title'>{title}</h2>
      <div className='header__right-wrapper'>
        {isBalanceHidden ? (
          ''
        ) : (
          <div className='header__balance-block'>
            <span className='header__balance-text'>
              Coin Balance:{' '}
              <span className='header__balance-text-number'>
                {wallet.balance?.toFixed(0) || '~'} <TrvIcon />
              </span>
            </span>
            <Link className='header__balance-link'>Top Up Balance</Link>
          </div>
        )}
        <ProfileDropdown />
      </div>
    </header>
  )
}

export default Header
