import React from 'react'
import MinimalTextarea from '../../../../../Shared/MinimalTextarea/MinimalTextarea'

import './AddressInfo.scss'

const AddressInfo = ({ disabled, address, onChange }) => {
  return (
    <div className='address-info'>
      <div className='address-info__inputs'>
        <MinimalTextarea
          value={address}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default AddressInfo
