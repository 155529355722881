import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SignHero from '../../Shared/SignHero/SignHero'
import SignUpForm from './SignUpForm/SignUpForm'
import axios from 'axios'

import './SignUp.scss'
import { pageTitles } from '../../../static/constants/pageTitles'

const SignUp = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [country, setCountry] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const handleSetFirstName = (event) => {
    setFirstName(event.target.value)
  }

  const handleSetLastName = (event) => {
    setLastName(event.target.value)
  }

  const handleSetEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleSetPassword = (event) => {
    setPassword(event.target.value)
  }

  const handleSetPasswordRepeat = (event) => {
    setPasswordRepeat(event.target.value)
  }

  const handleChangeCountry = (country) => {
    setCountry(country)
  }

  const handleSubmitSignup = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('name', firstName)
      formData.append('surname', lastName)
      formData.append('email', email)
      formData.append('password', password)
      formData.append('repeat_password', passwordRepeat)
      formData.append('country_id', country.id)
      const { data } = await axios.post(
        'https://api.travelingo.pro/api/vendor/registration',
        formData,
        {
          headers: {
            Authorization: 'Bearer api_for_travelingo',
          },
        }
      )
      if (data.status === 'error') {
        setErrorMessage(data.error)
      } else {
        navigate('/sign-up/confirmation-sent')
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `Travelingo | ${pageTitles.SIGN_UP}`
  }, [])

  return (
    <div className='sign-up'>
      <SignHero />
      <SignUpForm
        isLoading={isLoading}
        firstName={firstName}
        lastName={lastName}
        email={email}
        password={password}
        passwordRepeat={passwordRepeat}
        country={country}
        errorMessage={errorMessage}
        setFirstName={handleSetFirstName}
        setLastName={handleSetLastName}
        setEmail={handleSetEmail}
        setPassword={handleSetPassword}
        setPasswordRepeat={handleSetPasswordRepeat}
        setCountry={handleChangeCountry}
        submitForm={handleSubmitSignup}
      />
    </div>
  )
}

export default SignUp
