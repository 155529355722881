import ProfileSubheader from '../../../../../Shared/ProfileSubheader/ProfileSubheader'
import RowInput from '../../../../../Shared/RowInput/RowInput'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCookie } from '../../../../../../hooks/cookies'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import './CashierEdit.scss'
import ErrorMessage from '../../../../../Shared/ErrorMessage/ErrorMessage'
import LoadingCover from '../../../../../Shared/LoadingCover/LoadingCover'

const CashierEdit = () => {
  const [cashier, setCashier] = useState({})
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const company = useSelector((state) => state.company.company)

  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  const handleChangeLastName = (event) => {
    setLastName(event.target.value)
  }

  const handleChangePhone = (event) => {
    setPhone(event.target.value)
  }

  const setInitialData = ({ name, surname, phone }) => {
    setName(name)
    setLastName(surname)
    setPhone(phone === 'null' ? '' : phone)
  }

  const getCashier = async () => {
    try {
      const { data } = await axios.get(
        'https://api.travelingo.pro/api/vendor/get_cashiers',
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
            cashier_id: id,
          },
        }
      )
      setCashier(data)
      setInitialData(data)
      if (data.status === 'error') {
        setErrorMessage(data.error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitCreateCashier = async (event) => {
    setErrorMessage('')
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('email', cashier.email)
      formData.append('phone', phone)
      formData.append('name', name)
      formData.append('surname', lastName)
      formData.append('company_id', company.id)
      formData.append('cashier_id', cashier.user_id)
      const { data } = await axios.post(
        `https://api.travelingo.pro/api/vendor/set_cashier`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
      if (data.status === 'error') {
        setErrorMessage(data.error)
      } else {
        navigate(-1)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCashier()
  }, [])

  return (
    <form className='cashier-edit' onSubmit={handleSubmitCreateCashier}>
      <ProfileSubheader
        title='Edit an Employee'
        description={`Edit an employee ${cashier.name} ${cashier.surname}`}
      >
        <div className='cashier-edit__buttons'>
          <button
            onClick={() => navigate(-1)}
            className='cashier-edit__button-cancel'
            type='button'
          >
            Cancel
          </button>
          <button className='cashier-edit__button-save' type='submit'>
            Save
          </button>
        </div>
      </ProfileSubheader>
      <div className='cashier-edit__error-wrapper'>
        <ErrorMessage message={errorMessage} />
      </div>
      <div className='cashier-edit__row-input-wrapper'>
        <RowInput name='Login' description='Email'>
          <p className='cashier-edit__row-input-email'>{cashier.email}</p>
        </RowInput>
      </div>
      <div className='cashier-edit__row-input-wrapper'>
        <RowInput name='Password' description="Employee's account password">
          <p className='cashier-edit__password-description'>
            The password will be sent to the user's email after saving
          </p>
        </RowInput>
      </div>
      <div className='cashier-edit__row-input-wrapper'>
        <RowInput
          value={name}
          onChange={handleChangeName}
          placeholder='John'
          name='First Name'
          description='First name of the employee'
          isRequired
        />
      </div>
      <div className='cashier-edit__row-input-wrapper'>
        <RowInput
          value={lastName}
          onChange={handleChangeLastName}
          placeholder='Doe'
          name='Last Name'
          description='Last name of the employee'
          isRequired
        />
      </div>
      <div className='cashier-edit__row-input-wrapper'>
        <RowInput
          value={phone}
          onChange={handleChangePhone}
          placeholder='+123456789'
          name='Phone number'
          description="Employee's phone number"
        />
      </div>
      {isLoading ? <LoadingCover /> : ''}
    </form>
  )
}

export default CashierEdit
