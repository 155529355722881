import React from 'react'
import Modal from 'react-modal'
import CustomButton, {
  BUTTON_STYLES,
} from '../../../../Shared/CustomButton/CustomButton'

import './LogoutModal.scss'

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.70)',
    zIndex: 999,
  },
  content: {
    padding: '48px 105px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'static',
    width: '287px',
    height: 'fit-content',
    borderRadius: '16px',
  },
}

const LogoutModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      className='logout-modal'
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <h3 className='logout-modal__title'>Log Out</h3>
      <p className='logout-modal__description'>Are you sure want to log out?</p>
      <div className='logout-modal__buttons'>
        <div className='logout-modal__submit'>
          <CustomButton onClick={onSubmit} label='Yes' />
        </div>
        <div className='logout-modal__cancel'>
          <CustomButton
            onClick={onClose}
            label='No'
            style={BUTTON_STYLES.EMPTY}
          />
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
