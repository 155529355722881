import React, { useEffect } from 'react'
import Header from '../Header/Header'
import ProfileNavigation from './ProfileNavigation/ProfileNavigation'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import './Profile.scss'

const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/profile') {
      // navigate('about-company')
    }
  })

  return (
    <div className='profile'>
      <Header title='Profile' />
      <div className='profile__navigation-wrapper'>
        <ProfileNavigation />
      </div>
      <Outlet />
    </div>
  )
}

export default React.memo(Profile)
