import React from 'react'
import TariffBlock from './TariffBlock/TariffBlock'
import TariffDescription from './TariffDescription/TariffDescription'
import TariffBlockInactive from './TariffBlockInactive/TariffBlockInactive'

import './TariffSelect.scss'

const TariffSelect = () => {
  return (
    <div className='tariff-select'>
      <div className='tariff-select__tariffs'>
        <TariffBlock />
        <TariffBlockInactive />
      </div>
      <TariffDescription />
    </div>
  )
}

export default TariffSelect
