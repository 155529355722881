import React, { useState } from 'react'
import Input from '../../../Shared/Input/Input'
import PasswordInput from '../../../Shared/PasswordInput/PasswordInput'
import Checkbox from '../../../Shared/Checkbox/Checkbox'
import { Link } from 'react-router-dom'
import { emailRegex } from '../../../../hooks/regex'
import CustomButton from '../../../Shared/CustomButton/CustomButton'

import './SignInForm.scss'
import ErrorMessage from '../../../Shared/ErrorMessage/ErrorMessage'

const SignInForm = ({
  isLoading,
  errorMessage,
  email,
  password,
  isRememberChecked,
  setEmail,
  setPassword,
  setIsRememberChecked,
  submitForm,
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const toggleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  return (
    <div className='sign-in-form'>
      <h3 className='sign-in-form__title'>Sign In</h3>
      <form className='sign-in-form__form' onSubmit={submitForm}>
        <Input
          value={email}
          onChange={setEmail}
          isRequired
          type='email'
          label='Email'
          pattern={emailRegex.source}
          errorMessage='Please provide valid email address'
        />
        <div className='sign-in__password-input'>
          <PasswordInput
            value={password}
            onChange={setPassword}
            isRequired
            label='Password'
            togglePassword={toggleShowPassword}
            isPasswordShown={isPasswordShown}
          />
        </div>
        <Link to='/forgot-password' className='sign-in__forgot-password'>
          Forgot Password?
        </Link>
        {errorMessage ? (
          <div className='sign-in__error-message'>
            <ErrorMessage message={errorMessage} />
          </div>
        ) : (
          ''
        )}
        <div className='sign-in__submit-wrapper'>
          <CustomButton label='Sign In' isLoading={isLoading} />
        </div>
        <div className='sign-in__checkbox'>
          <Checkbox
            label='Remember me'
            isChecked={isRememberChecked}
            toggleCheckbox={setIsRememberChecked}
          />
        </div>
        <span className='sign-in__sign-up'>
          Don't have an account?{' '}
          <Link to='/sign-up' className='sign-in__sign-up-link'>
            Sign Up
          </Link>
        </span>
      </form>
    </div>
  )
}

export default SignInForm
