import ProfileSubheader from '../../../../Shared/ProfileSubheader/ProfileSubheader'
import { getCookie } from '../../../../../hooks/cookies'
import CashierInfo from './CashierInfo/CashierInfo'
import CashierQr from './CashierQr/CashierQr'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import './CashierProfile.scss'

const CashierProfile = () => {
  const [cashier, setCashier] = useState({})
  const [isActive, setIsActive] = useState(!!cashier.active_user)
  const params = useParams()
  const company = useSelector((state) => state.company.company)
  const navigate = useNavigate()

  const handleToggleActive = async () => {
    const initialActive = isActive
    try {
      setIsActive(!initialActive)
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('cashier_id', cashier.user_id)
      formData.append('status', Number(!initialActive))
      await axios.post(
        `https://api.travelingo.pro/api/vendor/set_cashier_status`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
    } catch (error) {
      console.log(error)
      setIsActive(initialActive)
    }
  }

  const getCashier = async () => {
    try {
      const { data } = await axios.get(
        'https://api.travelingo.pro/api/vendor/get_cashiers',
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
            cashier_id: params.id,
          },
        }
      )
      setCashier(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCashier()
  }, [])

  useEffect(() => {
    setIsActive(!!cashier.active_user)
  }, [cashier])

  return (
    <div className='cashier-profile'>
      <ProfileSubheader
        title="Employee's profile"
        description='You can change employee details or delete a profile'
      >
        <div className='cashier-profile__buttons'>
          <button
            onClick={() => navigate('/staff')}
            className='cashier-profile__button-cancel'
            type='button'
          >
            Go Back
          </button>
          <Link
            to='edit'
            className='cashier-profile__button-edit'
            type='button'
          >
            Edit
          </Link>
        </div>
      </ProfileSubheader>
      <div className='cashier-profile__content'>
        <div className='cashier-profile__info'>
          <CashierInfo
            name={cashier.name}
            lastName={cashier.surname}
            email={cashier.email}
            phone={cashier.phone}
            address={cashier.point_info?.address}
            color={cashier.point_info?.color_tag}
            isActive={isActive}
            toggleIsActive={handleToggleActive}
          />
        </div>
        <div className='cashier-profile__qr'>
          <CashierQr />
          <span className='cashier-profile__referals-title'>
            Referred clients
          </span>
          <span className='cashier-profile__referals-amount'>12</span>
        </div>
      </div>
    </div>
  )
}

export default CashierProfile
