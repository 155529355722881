import React from 'react'

import './AddressBadge.scss'

const AddressBadge = ({ label, color, onChange, disabled }) => {
  return (
    <div className='address-badge'>
      <span className='address-badge__name'>{label}</span>
      <input
        value={color}
        onChange={onChange}
        style={{ backgroundColor: color, boxSizing: 'border-box' }}
        className='address-badge__color'
        type='color'
        disabled={disabled}
      />
    </div>
  )
}

export default AddressBadge
