import { createSlice } from '@reduxjs/toolkit'

export const cashiersSlice = createSlice({
  name: 'cashiers',
  initialState: {
    searchQuery: '',
    branchFilters: [],
    cashiers: [],
  },
  reducers: {
    setCashiers: (state, action) => {
      state.cashiers = [...action.payload]
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setBranchFilters: (state, action) => {
      state.branchFilters = action.payload
    },
  },
})

export const { setCashiers, setSearchQuery, setBranchFilters } =
  cashiersSlice.actions

export default cashiersSlice.reducer
