import React, { useEffect, useState } from 'react'
import ProfileSubHeader from '../../../../Shared/ProfileSubheader/ProfileSubheader'
import BranchSelect from '../../../../Shared/BranchSelect/BranchSelect'
import LoadingCover from '../../../../Shared/LoadingCover/LoadingCover'
import ErrorMessage from '../../../../Shared/ErrorMessage/ErrorMessage'
import { setCashiers } from '../../../../../store/cashiersSlice'
import RowInput from '../../../../Shared/RowInput/RowInput'
import { getCookie } from '../../../../../hooks/cookies'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

import './CreateCashier.scss'
import { emailRegex, phoneRegex } from '../../../../../hooks/regex'

const CreateCashier = () => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [point, setPoint] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [points, setPoints] = useState([])
  const company = useSelector((state) => state.company.company)
  const cashiers = useSelector((state) => state.cashiers.cashiers)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChangeBranch = (category) => {
    setErrorMessage('')
    setPoint(category)
  }

  const handleChangePhone = (event) => {
    setErrorMessage('')
    setPhone(event.target.value)
  }

  const handleChangeName = (event) => {
    setErrorMessage('')
    setName(event.target.value)
  }

  const handleChangeLastName = (event) => {
    setErrorMessage('')
    setLastName(event.target.value)
  }

  const handleChangeEmail = (event) => {
    setErrorMessage('')
    setEmail(event.target.value)
  }

  const getAllPoints = async () => {
    try {
      const { data } = await axios.get(
        `https://api.travelingo.pro/api/vendor/get_point_info`,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
          params: {
            user_id: getCookie('userId'),
            company_id: company.id,
          },
        }
      )
      setPoints(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitCreateCashier = async (event) => {
    setErrorMessage('')
    event.preventDefault()
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('user_id', getCookie('userId'))
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('name', name)
      formData.append('surname', lastName)
      formData.append('company_id', company.id)
      formData.append('point_id', point.id)
      const { data } = await axios.post(
        `https://api.travelingo.pro/api/vendor/set_cashier`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getCookie('userToken')}`,
          },
        }
      )
      if (data.status === 'error') {
        setErrorMessage(data.error)
      } else {
        dispatch(setCashiers([...cashiers, data]))
        navigate('/staff')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllPoints()
  }, [])

  return (
    <form className='create-cashier' onSubmit={handleSubmitCreateCashier}>
      <ProfileSubHeader
        title='Add an Employee'
        description='Register an employee for access to the application'
      >
        <div className='create-cashier__buttons'>
          <button
            onClick={() => navigate(-1)}
            className='create-cashier__button-cancel'
            type='button'
          >
            Cancel
          </button>
          <button className='create-cashier__button-save' type='submit'>
            Save
          </button>
        </div>
      </ProfileSubHeader>
      <div className='create-cashier__error-wrapper'>
        <ErrorMessage message={errorMessage} />
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput
          value={email}
          onChange={handleChangeEmail}
          placeholder='johndoe@gmail.com'
          name='Login'
          description='Email'
          isRequired
          pattern={emailRegex.source}
        />
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput name='Password' description="Employee's account password">
          <p className='create-cashier__password-description'>
            The password will be sent to the user's email after saving
          </p>
        </RowInput>
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput
          value={name}
          onChange={handleChangeName}
          placeholder='John'
          name='First Name'
          description='First name of the employee'
          isRequired
        />
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput
          value={lastName}
          onChange={handleChangeLastName}
          placeholder='Doe'
          name='Last Name'
          description='Last name of the employee'
          isRequired
        />
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput
          name='Branch'
          description='The address where the employee works'
          isRequired
        >
          <BranchSelect selectedOption={point} onChange={handleChangeBranch} />
        </RowInput>
      </div>
      <div className='create-cashier__row-input-wrapper'>
        <RowInput
          value={phone}
          onChange={handleChangePhone}
          placeholder='+123456789'
          name='Phone number'
          description="Employee's phone number"
          pattern={phoneRegex.source}
        />
      </div>
      {isLoading ? <LoadingCover /> : ''}
    </form>
  )
}

export default CreateCashier
